import React from 'react'
import ReactDOM from 'react-dom/client'
import blue from '@material-ui/core/colors/blue'

import App from '@newspaces/core-app/App'
import { bootstrapAppContext } from '@newspaces/core-app/AppContext'

import i18n from './i18n'
import createContext from './context'

import { AdminIcon, LocatorIcon, SetupIcon } from './Icons'

const main = async () => {
    await bootstrapAppContext(
        createContext({
            apps: [
                {
                    key: 'admin',
                    scopes: ['organisation'],
                    showLoader: true,
                    name: () => i18n().apps.admin,
                    Icon: AdminIcon,
                    import: () => import('./Admin'),
                    mobile: false,
                    getTheme: () => ({
                        theme: {
                            secondaryColor: '#A91B80',
                            route: {
                                main: blue[500],
                                dark: blue[800],
                            },
                        }
                    }),
                },
                {
                    key: 'locator',
                    scopes: ['organisation', 'device'],
                    showLoader: true,
                    name: () => i18n().apps.locator,
                    Icon: LocatorIcon,
                    import: () => import('./Locator'),
                    mobile: false,
                    getTheme: () => ({
                        theme: {
                            secondaryColor: '#A91B80',
                            route: {
                                main: blue[500],
                                dark: blue[800],
                            },
                        }
                    }),
                },
                {
                    key: 'setup',
                    scopes: ['organisation', 'device.edit', 'mission.read'],
                    showLoader: true,
                    name: () => i18n().apps.setup,
                    Icon: SetupIcon,
                    import: () => import('./Setup'),
                    getTheme: () => ({
                        theme: {
                            typography: {
                                button: {
                                    fontSize: 16,
                                }
                            }
                        }
                    }),
                },
                {
                    key: 'api',
                    isHidden: true,
                    isGlobal: true,
                    showLoader: true,
                    import: async () => {
                        await import('thingfox-base/models')
                        return import('@newspaces/api-app')
                    },
                },
            ],
        })
    )

    const root = ReactDOM.createRoot(document.querySelector('#root'))
    root.render(<App />)
}

main()
