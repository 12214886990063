/* eslint-disable import/prefer-default-export */
import React, { useMemo } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core/styles'
import { fade } from '@newspaces/lucky-components/utils'


// eslint-disable-next-line no-unused-vars
const useColors = baseColor => {
    const theme = useTheme()

    const color = theme.palette[baseColor]?.main || baseColor || theme.palette.primary.main

    return useMemo(() => ({
        color1: fade(color, 0.54, 'black'),
        color2: fade(color, 0.24, 'white'),
        color3: fade(color, 0.08, 'white'),
    }), [color])
}
    

export const AdminIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <path d="M13.5,19.993a29.147,29.147,0,0,1,5-5.5,5.369,5.369,0,0,1,3.307-1.446,1,1,0,0,0,.838-1.515,9.516,9.516,0,0,0-3.189-3.19,7.419,7.419,0,0,0,.464-5.589A1,1,0,0,0,18.4,2.177c-1.371.921-3.568,2.657-3.876,4.5a17.516,17.516,0,0,0-5.037,0C9.173,4.834,6.976,3.1,5.605,2.177a1,1,0,0,0-1.525.576,7.42,7.42,0,0,0,.465,5.589,9.507,9.507,0,0,0-3.189,3.19,1,1,0,0,0,.837,1.515A5.367,5.367,0,0,1,5.5,14.493a29.147,29.147,0,0,1,5,5.5Z" style="fill:${colors.color2}"/>
  <path d="M8.453,7.774a1.77,1.77,0,0,0,1.759.98c.568-.048,1.161-.076,1.788-.076s1.221.028,1.789.076a1.771,1.771,0,0,0,1.759-.98c.9-1.87,3.046-3.546,4.49-4.519-.038-.176-.077-.346-.118-.5A1,1,0,0,0,18.4,2.177c-1.371.921-3.568,2.657-3.876,4.5a17.516,17.516,0,0,0-5.037,0c-.309-1.841-2.5-3.577-3.877-4.5a1,1,0,0,0-1.525.576c-.041.156-.08.326-.118.5C5.406,4.228,7.555,5.9,8.453,7.774Z" style="fill:${colors.color3}"/>
  <path d="M21.309,9.825a5.789,5.789,0,0,0-2.809,1.4,28.922,28.922,0,0,0-4.171,4.371,2.906,2.906,0,0,1-2.294,1.129h-.07a2.9,2.9,0,0,1-2.293-1.129A28.937,28.937,0,0,0,5.5,11.223a5.79,5.79,0,0,0-2.808-1.4,9.346,9.346,0,0,0-1.336,1.707,1,1,0,0,0,.837,1.515A5.367,5.367,0,0,1,5.5,14.493a29.147,29.147,0,0,1,5,5.5h3a29.147,29.147,0,0,1,5-5.5,5.369,5.369,0,0,1,3.307-1.446,1,1,0,0,0,.838-1.515A9.391,9.391,0,0,0,21.309,9.825Z" style="fill:#ffffff"/>
  <path d="M13.5,19.993a29.147,29.147,0,0,1,5-5.5,5.369,5.369,0,0,1,3.307-1.446,1,1,0,0,0,.838-1.515,9.516,9.516,0,0,0-3.189-3.19,7.419,7.419,0,0,0,.464-5.589A1,1,0,0,0,18.4,2.177c-1.371.921-3.568,2.657-3.876,4.5a17.516,17.516,0,0,0-5.037,0C9.173,4.834,6.976,3.1,5.605,2.177a1,1,0,0,0-1.525.576,7.42,7.42,0,0,0,.465,5.589,9.507,9.507,0,0,0-3.189,3.19,1,1,0,0,0,.837,1.515A5.367,5.367,0,0,1,5.5,14.493a29.147,29.147,0,0,1,5,5.5Z" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M9.25,11.743a.249.249,0,0,1,.25.25" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M9,11.993a.249.249,0,0,1,.25-.25" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M9.25,12.243a.25.25,0,0,1-.25-.25" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M9.5,11.993a.25.25,0,0,1-.25.25" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M14.75,11.743a.249.249,0,0,0-.25.25" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M15,11.993a.249.249,0,0,0-.25-.25" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M14.75,12.243a.25.25,0,0,0,.25-.25" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M14.5,11.993a.25.25,0,0,0,.25.25" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
  <path d="M10.5,19.993v.5a1.5,1.5,0,0,0,3,0v-.5" style="fill:${colors.color2};stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="10.5" y1="19.993" x2="13.5" y2="19.993" style="stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round;fill:none"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const LocatorIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <ellipse cx="12" cy="21.5" rx="11.5" ry="2" style="fill:${colors.color2}"/>
  <path d="M12,.5a6.856,6.856,0,0,1,6.855,6.856c0,3.215-4.942,11.185-6.434,13.517a.5.5,0,0,1-.842,0c-1.492-2.332-6.434-10.3-6.434-13.517A6.855,6.855,0,0,1,12,.5Z" style="fill:${colors.color2}"/>
  <path d="M12,21.1a.5.5,0,0,1-.421-.231c-1.492-2.332-6.434-10.3-6.434-13.517A6.855,6.855,0,0,1,12,.5Z" style="fill:${colors.color3}"/>
  <circle cx="12" cy="7" r="3.5" style="fill:${colors.color3}"/>
  <path d="M12,10.5a3.5,3.5,0,0,1,0-7Z" style="fill:#ffffff"/>
  <circle cx="12" cy="7" r="3.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M12,.5a6.856,6.856,0,0,1,6.855,6.856c0,3.215-4.942,11.185-6.434,13.517a.5.5,0,0,1-.842,0c-1.492-2.332-6.434-10.3-6.434-13.517A6.855,6.855,0,0,1,12,.5Z" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M17,19.7c3.848.324,6.5,1.009,6.5,1.8,0,1.105-5.148,2-11.5,2S.5,22.605.5,21.5c0-.79,2.635-1.473,6.458-1.8" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})

export const SetupIcon = React.forwardRef(({ color, ...props }, ref) => {
    const colors = useColors(color)
    const svg = `<g>
  <rect x="5.5" y="0.5" width="13" height="23" rx="2" style="fill:${colors.color3}"/>
  <rect x="5.5" y="4.5" width="13" height="15" style="fill:${colors.color2}"/>
  <polygon points="18.5 5.231 18.5 4.5 5.5 4.5 5.5 18.231 18.5 5.231" style="fill:${colors.color3}"/>
  <rect x="5.5" y="0.5" width="13" height="23" rx="2" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="18.5" y1="4.5" x2="5.5" y2="4.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="18.5" y1="19.5" x2="5.5" y2="19.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="9.5" y1="2.5" x2="14.5" y2="2.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <path d="M12,21.25h0a.25.25,0,0,1,.25.25h0a.25.25,0,0,1-.25.25h0a.25.25,0,0,1-.25-.25h0a.25.25,0,0,1,.25-.25" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <rect x="7.496" y="7.5" width="2" height="2" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <rect x="7.496" y="14.5" width="2" height="2" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <rect x="14.496" y="7.5" width="2" height="2" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <polyline points="7.496 12.5 11.496 12.5 11.496 13.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <polyline points="13.496 13.5 13.496 16.5 16.496 16.5 16.496 13.5 15.496 13.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="11.496" y1="15.5" x2="11.496" y2="16.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <polyline points="11.496 7.5 11.496 10.5 12.496 10.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
  <line x1="14.496" y1="11.5" x2="16.496" y2="11.5" style="fill:none;stroke:${colors.color1};stroke-linecap:round;stroke-linejoin:round"/>
</g>
`
    return (<SvgIcon ref={ref} viewBox="0 0 24 24" {...props}><g dangerouslySetInnerHTML={{ __html: svg }} /></SvgIcon>)
})
