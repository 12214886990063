import nanoid from 'nanoid'

import Model from '@newspaces/lucky-base/Model'
import LuckyOrganisation from '@newspaces/lucky-base/models/Organisation'

class Organisation extends LuckyOrganisation {
    static DefaultCountry = 'DE'

    static Permissions = Model.mergePermissions(LuckyOrganisation.Permissions, {
        read: {
            who: 'owner',
            scope: 'organisation.read',
            fields: [
                'claimToken',
                'timocomIntegrationEnabled',
                'timocomIntegrationPassword',
                'sqlAnalytics',
            ],
        },
        edit: {
            who: 'owner',
            scope: 'organisation.edit',
            fields: [
                'claimToken',
                'timocomIntegrationEnabled',
                'timocomIntegrationPassword',
                'sqlAnalytics',
            ],
        },
    })

    static Fields = {
        country: {
            ...LuckyOrganisation.Fields.country,
            required: true,
        },

        claimToken: {
            type: String,
            required: true,
            default: nanoid,
        },

        // -- timocom integration
        // https://my.timocom.com/static/shared/doc/atlas/AtlasRESTDocumentation.html
        timocomIntegrationEnabled: {
            type: Boolean,
        },

        timocomIntegrationPassword: {
            type: String,
            required: ({ timocomIntegrationEnabled }) => timocomIntegrationEnabled
        },

        // -- can only be set from system --

        // How long failed webhook jobs stick in the system
        failedWebhookAgeDays: {
            type: Number,
            default: 30,
            serialize: 'insert',
        },

        // How long successfull webhook jobs stick in the system
        successWebhookAgeDays: {
            type: Number,
            default: 3,
            serialize: 'insert',
        },

        // Maximum age of telemetry data points to be purged
        telemetryDataPointsAgeDays: {
            type: Number,
            default: 30,
            serialize: 'insert',
        },

        // Current configuration version counter for t-configurations
        tconfigurationVersion: {
            type: Number,
            serialize: false,
        },

        sqlAnalytics: {
            type: Boolean,
            default: true,
        },
    }

    constructor(data) {
        super(Organisation.Fields, data)
    }
}

export default Model.declareModel(Organisation)
