import Model from '@newspaces/lucky-base/Model'
import LuckyUserData from '@newspaces/lucky-base/models/UserData'

class UserData extends LuckyUserData {
    static Fields = {}

    constructor(data) {
        super(UserData.Fields, data)
    }
}

export default Model.declareModel(UserData)
