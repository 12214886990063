/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */

export default {
    organisation: {
        timocomIntegrationEnabled: 'TIMOCOM Integration',
        timocomIntegrationPassword: 'TIMOCOM Password',
        sqlAnalytics: 'SQL Analytics',
    },
    device: {
        modelName: {
            single: 'Device',
            multi: 'Devices',
        },
        type: 'Type',
        deviceId: 'Identifier',
        name: 'Name',
        labels: 'Labels',
        lastSeenAt: 'Last seen',
        cargoId: 'Cargo ID',
        telemetry: {
            reason: 'Last reason'
        }
    },
    mission: {
        modelName: {
            single: 'Mission',
            multi: 'Missions',
        },
        instances: 'Instances',
        deviceType: 'Device Type',
        name: 'Name',
        description: 'Description',
        labels: 'Labels',
        properties: 'Properties',
        requiresCargoId: 'Requires Cargo ID',
        route: 'Route',
        routeToleranceRadius: 'Tolerance Radius (meters)',
        routeZoneFilterLabels: 'Zone Filter Labels',
        webhook: 'Webhook',
        zoneFilterLabels: 'Zone Filter Labels',
        beaconMappingMode: 'Beacon Mapping Mode',
        beaconMappingModeValue: {
            closestMax: 'Closest Max',
            closestAverage: 'Closest Average',
            takeClosest: 'Take Closest',
        },
        attachDetach: {
            attachEmptyOnly: 'Attach Empty Only',
            transferMission: 'Transfer To Mission',
            attachZoneLabels: 'Zone Labels',
            detachZoneLabels: 'Zone Labels',
            detachLeaveZoneLabels: 'Zone Labels',
            restrictTransfer: 'Restrict Transfer',

            attachEmptyOnlyInfo:
                'Only attach this mission if the device isn\'t attached to any mission',
            attachZoneLabelsInfo:
                'Add zone labels so that when the device reaches any zone with ' +
                'this label, the device will be attached to this mission',
            detachZoneLabelsInfo:
                'Add zone labels so that when the device reaches any zone with ' +
                'this label, the device will be detached from this mission',
            detachLeaveZoneLabelsInfo:
                'Add zone labels so that when the device leaves any zone with ' +
                'this label, the device will be detached from this mission',
            restrictTransferInfo:
                'Restrict the device to only allow it to be transferred to the ' +
                'selected "Transfer Mission"'
        },

        beaconMappingModeInfo: 'Method used to map beacons to a region',
        beaconMappingModeValueInfo: {
            closestMax: 'Map to the region that has the most beacons, among the 6 closest beacons.',
            closestAverage:
                'Calculate an average distance between beacons and devices, ' +
                'and map to region that have the lowest average distance.',
            takeClosest: 'Map to region of closest beacon.',
        }
    },
    route: {
        modelName: {
            single: 'Route',
            multi: 'Routes',
        },
        name: 'Name',
        description: 'Description',
    },
    zone: {
        modelName: {
            single: 'Zone',
            multi: 'Zones',
        },
        name: 'Name',
        description: 'Description',
        labels: 'Labels',
        moveable: 'Movable',
        properties: 'Properties',
        place: 'Place',
        region: {
            modelName: {
                single: 'Region',
                multi: 'Regions',
            },
            name: 'Name',
            description: 'Description',
            labels: 'Labels',
            floor: 'Floor',
            majorVersion: 'Major Version',
            minBeacons: 'Min Beacons',
            properties: 'Properties',
            beacon: {
                modelName: {
                    single: 'Beacon',
                    multi: 'Beacons',
                },
                type: 'Type',
                typeValue: {
                    blueup: 'BlueUp',
                    other: 'Other',
                },
                macAddress: 'MAC Address',
                txPower: 'TX Power',
                pathLossFactor: 'Path Loss Factor',
                maxDistance: 'Maximum Distance',
                batteryLevel: 'Battery Level',
                lastContactAt: 'Last Contact',
            },
        },
    },
    place: {
        name: 'Name',
        countryCode: 'Country',
        region: 'Region',
        place: 'Place',
        postcode: 'Postcode',
        street: 'Street',
        address: 'Address',
    },
    label: {
        modelName: {
            single: 'Label',
            multi: 'Labels',
        },
        label: 'Label',
        usageCount: 'Used count',
        createdAt: 'Created at',
        color: 'Color',
    },
    properties: {
        modelName: {
            single: 'Property',
            multi: 'Properties',
        },
        property: {
            key: 'Unique Key',
            name: 'Name',
            type: 'Data Type',
            typeValue: {
                string: 'Text',
                date: 'Date',
                float: 'Decimal Number',
                int: 'Regular Number',
                bool: 'True/False',
            },
        },
    },
    telemetry: {
        modelName: {
            single: 'Telemetry',
            multi: 'Telemetries',
        },
        telemetryTime: 'Timestamp',
        reason: 'Reason',
        device: {
            batteryLevel: 'Battery level',
            batteryPowerState: 'Battery charging',
            networkSignalStrength: 'Network Signal Strength',
            firmwareVersion: 'Firmware-Version',
        },
    },
    webhook: {
        modelName: {
            single: 'Webhook',
            multi: 'Webhooks',
        },
        name: 'Name',
        endpoint: 'Endpoint',
        authMethod: 'Authentication Method',
        authMethods: {
            none: 'None',
            basic: 'Basic',
            digest: 'Digest',
            oauth2: 'OAuth 2',
        },
        authUserName: 'Username',
        authPassword: 'Password',
        oauthTokenUrl: 'Token URL',
        oauthClientID: 'Client ID',
        oauthClientSecret: 'Client Secret',
        oauthScope: 'Scope',
        httpHeaders: 'HTTP Headers',
        httpHeadersKey: 'Key',
        httpHeadersValue: 'Value',
    },
    webhookJob: {
        modelName: {
            single: 'Webhook Job',
            multi: 'Webhook Jobs',
        },
        webhook: 'Webhook',
        referenceType: 'Reference Type',
        referenceTypeValue: {
            telemetry: 'Telemetry',
        },
        referenceId: 'Reference ID',
        payload: 'Payload',
        payloadType: 'Payload Type',
        payloadTypeValue: {
            json: 'JSON',
            text: 'Text',
        },
        success: 'Success',
        retries: 'Retries',
        retriedAt: 'Retried At',
        statusCode: 'Status Code',
        result: 'Response',
        resultType: 'Response Type',
        createdAt: 'Date',
        updatedAt: 'Sent At',

        status: 'Status',
        statusValue: {
            success: 'Success',
            error: 'Error',
            pending: 'Pending',
        },
    },
    tconfiguration: {
        beaconUUID: 'Beacon UUID',
        minMoveThreshold: 'Min. Move Threshold',
        assistNowAge: 'Assist Now Age',
        connectIdleTime: 'Connect Idle Time',

        // info
        beaconUUIDInfo:
            'A UUID that limits the beacon scanning to beacon advertisements ' +
            'with this UUID only ignoring any others. If receiving a full set ' +
            'of 0’s then the beacon scanning is not limited to any UUID.',
        minMoveThresholdInfo:
            'Defines the minimum threshold in mg/s used before any move is detected as a move at all.',
        assistNowAgeInfo:
            'Defines the maximum age for AssistNow offline data files in days. ' +
            'If set to zero the AssistNow offline feature is disabled.',
        connectIdleTimeInfo:
            'Defines the maximum idle time in minutes between last connection ' +
            'and a reconnect to check for a new configuration potentially waiting. ' +
            'A value of zero deactivates this behavior.',

        profile: {
            profileId: 'ID',
            profileName: 'Name',
            enterGesture: 'Enter Gesture',
            enterDelay: 'Enter Delay',
            exitGesture: 'Exit Gesture',
            exitDelay: 'Exit Delay',
            led: 'LED',
            scanOnActivation: 'Scan on Activation',
            scanOnDeactivation: 'Scan on Deactivation',
            scanInterval: 'Scan Interval',
            scanIntervalOnce: 'Scan Interval Once',
            locationScanMode: 'Location Scan Mode',
            locationScanTime: 'Location Scan Time',
            locationMinimumPrecision: 'Location Minimum Precision',
            beaconsScanTime: 'Beacons Scan Time',
            beaconsScanWindow: 'Beacons Scan Window',
            lightDetection: 'Light Detection',
            temperatureDetection: 'Temperature Detection',
            shockDetection: 'Shock Detection',
            tiltDetection: 'Tilt Detection',
            detectionWindow: 'Detection Window',
            detectionScan: 'Detection Scan',

            // info
            profileIdInfo: 'Unique ID of the profile',
            enterDelayInfo:
                'Delay in seconds the enter gesture needs to be active before this ' +
                'profile becomes active.',
            exitDelayInfo:
                'Delay in seconds the exit gesture needs to be active before this ' +
                'profile becomes inactive and jumps back to base profile.',
            ledInfo: 'Static color for the LED if it is using this profile.',
            scanOnActivationInfo:
                'If set to on, the scanning will take place immediately after this ' +
                'profile was activated and being sent. If this is activated but no ' +
                'location or beacon scanning is activated the system then simply sends ' +
                'an immediate message with the current data and profile.',
            scanOnDeactivationInfo:
                'If set to on, the scanning will take place immediately before this ' +
                'profile gets deactivated and being sent. If this is deactivated but no ' +
                'location or beacon scanning is activated the system then simply sends an ' +
                'immediate message with the current data and profile.',
            scanIntervalInfo: 'Scan interval defined by a given cron-expression.',
            scanIntervalOnceInfo:
                'If set to on and there is a scan interval provided then this will ' +
                'immediately stop after the first scan interval ever executed. ' +
                'The scan interval starts running after activation. If set to off ' +
                'it’ll repeat the scan interval when provided forever until this ' +
                'profile is deactivated.',
            locationScanTimeInfo:
                'Defines the time in seconds to use for fixing GNSS position and ' +
                'creating a reasonably accurate position. A value of zero means to ' +
                'scan only one time as quickly as possible (TTFF).',
            locationMinimumPrecisionInfo:
                'The amount of minimum precision in meters required to consider a ' +
                'stable fix which may happen before the LocationScanTime runs out. ' +
                'A value of zero means to deactivate this and take the fix after the ' +
                'LocationScanTime. If LocationScanTime is zero and this is zero it ' +
                'means the first fix received independent from accuracy.',
            beaconsScanTimeInfo:
                'Defines the time in seconds to use for scanning beacon advertisements ' +
                'and creating a reasonable default view. A value of zero deactivates ' +
                'beacons scanning completely.',
            beaconsScanWindowInfo:
                'The amount of delays between each beacon advertisements scanning in ' +
                'milliseconds. This has no effect if beacon scanning is deactivated.',
            lightDetectionInfo:
                'Defines the lower and upper limits for the light sensor to trigger ' +
                'an event. If both values are set to the same value (i.e. [0,0]) then ' +
                'this deactivates light interrupt detection altogether.',
            temperatureDetectionInfo:
                'Defines the lower and upper limits for the temperature sensor to ' +
                'trigger an event. If both values are set to the same value (i.e. [0,0]) ' +
                'then this deactivates temperature interrupt detection altogether.',
            shockDetectionInfo: 'Defines the range for a shock to be detected as shock.',
            tiltDetectionInfo: 'Defines the limits for a tilt to be detected as a tilt.',
            detectionWindowInfo:
                'The window in seconds between a detection (Temp, Shock, Light etc.) ' +
                'and the next measurement. For example if set to 900 seconds and a ' +
                'shock occurs it’ll ignore all further shocks the next 900 seconds ' +
                'before detecting again.',
            detectionScanInfo:
                'If set to On then whenever a change is detected (temp, tilt, light, ' +
                'shock etc.) the current time of occurence will be stored as “EventTime”. ' +
                'Then the device will scan its location and beacons (if desired) for this ' +
                'profile in the given timeframe and after that has finished will send the ' +
                'message with all updated information. If this is set to Off the last stored ' +
                'values for beacons/location will be used instead.',

            // fields
            lightDetectionPlaceholders: {
                minimum: 'Minimum',
                maximum: 'Maximum',
            },
            temperatureDetectionPlaceholders: {
                minimum: 'Minimum',
                maximum: 'Maximum',
            },
            shockDetectionPlaceholders: {
                threshold: 'Threshold',
                duration: 'Duration',
                window: 'Window',
            },
            tiltDetectionPlaceholders: {
                axis: 'Axis',
                minimumAngle: 'Minimum Angle',
                maximumAngle: 'Maximum Angle',
            },

            // infos
            shockDetectionInfos: {
                threshold: 'The threshold in mg. A value of zero means shock-detection is disabled.',
                duration: 'Duration of a single shock in ms.',
                window:
                    'Window to detect multiple shocks within in ms to increase shock counter. ' +
                    'If zero fires immediately after first shock.',
            },
            tiltDetectionInfos: {
                axis: 'The calibrated axis to watch for.',
                minimumAngle: 'Lower limit angle',
                maximumAngle: 'Upper limit angle',
            },
        },

        gestures: {
            move: 'Move',
            stop: 'Stop',
        },

        locationScanModes: {
            None: 'None',
            GSM: 'GSM',
            GNSS: 'GNSS',
            GNSS_GSM: 'GNSS + GSM',
            CLOUD: 'CLOUD',
        },

        tiltDetectionAxis: {
            none: 'None',
            x: 'X-Axis',
            y: 'Y-Axis',
            z: 'Z-Axis',
        },
    },
    notificationRule: {
        modelName: {
            single: 'Notification',
            multi: 'Notifications',
        },
        devices: 'Devices',
        labels: 'Labels',
        reasons: 'Reasons',
        receivers: 'Receivers',
        createdAt: 'Created at'
    },
    notification: {
        subject: 'Notification: Device "${this.deviceId}" received "${this.reason}" event.',
        text: 'At ${this.date}, the device "${this.deviceId}" received a telemetry update due to a "${this.reason}" event.\nFor more information, check the device details at ${this.url}'
    }
}
