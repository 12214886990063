import { configure } from 'mobx'

// THIS MUST BE LOADED FIRST BEFORE ANYTHING ELSE
// eslint-disable-next-line import/order
import './i18n'

import 'thingfox-base/i18n' // patch
import Organisation from 'thingfox-base/models/Organisation'
import OrganisationUser from 'thingfox-base/models/OrganisationUser'
import UserData from 'thingfox-base/models/UserData'

import thingfox from './zones/thingfox'

configure({
    enforceActions: 'never',
})

export default settings => ({
    ...settings,
    Organisation,
    OrganisationUser,
    UserData,
    zones: [thingfox],
})
