import { ReactComponent as Icon } from './icon.svg'
import { ReactComponent as Logo } from './logo.svg'
import FavIcon from './favicon.png'
import Cover from './cover.png'

import zone from './zone.json'
import productionBilling from './productionBilling.json'
import developmentBilling from './developmentBilling.json'

const billing = {
    development: developmentBilling,
    production: productionBilling
}

export default {
    ...zone,
    ...billing[process.env.REACT_APP_LUCKY_SCRIPTS_ENVIRONMENT || 'development'],
    Icon,
    Logo,
    FavIcon,
    Cover,
}
