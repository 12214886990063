import Model from '@newspaces/lucky-base/Model'
import LuckyOrganisationUser from '@newspaces/lucky-base/models/OrganisationUser'

class OrganisationUser extends LuckyOrganisationUser {
    static DefaultCountry = 'EN'

    static Permissions = LuckyOrganisationUser.Permissions

    static Fields = {}

    constructor(data) {
        super(OrganisationUser.Fields, data)
    }
}

export default Model.declareModel(OrganisationUser)
